const tabs = [
  {
    id: 0,
    name: 'domodedovo',
    tabName: 'Домодедовский район',
  },
  {
    id: 1,
    name: 'chehov',
    tabName: 'Чеховский район',
  },
  {
    id: 2,
    name: 'podolsk',
    tabName: 'Подольский район',
  },
  {
    id: 3,
    name: 'stupinskii',
    tabName: 'Ступинский район',
  },
];

export { tabs };
