import React from 'react';
import styles from './price.module.scss';

function Price() {
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>Цены на бетон</h2>
      <table className={styles.table}>
        <tr>
          <th className={styles.table__title}>Бетон</th>
          <th className={styles.table__title}>Цена</th>
        </tr>
        <tr>
          <td className={styles.table__item}>М400</td>
          <td className={styles.table__item}>6 450 <span className={styles.table__item__small}>р/м<sup>3</sup></span></td>
        </tr>
        <tr>
          <td className={styles.table__item}>М350</td>
          <td className={styles.table__item}>6 100 <span className={styles.table__item__small}>р/м<sup>3</sup></span></td>
        </tr>
        <tr>
          <td className={styles.table__item}>М300</td>
          <td className={styles.table__item}>5 900 <span className={styles.table__item__small}>р/м<sup>3</sup></span></td>
        </tr>
        <tr>
          <td className={styles.table__item}>М250</td>
          <td className={styles.table__item}>5 800 <span className={styles.table__item__small}>р/м<sup>3</sup></span></td>
        </tr>
        <tr>
          <td className={styles.table__item}>М200</td>
          <td className={styles.table__item}>5 600 <span className={styles.table__item__small}>р/м<sup>3</sup></span></td>
        </tr>
        <tr>
          <td className={styles.table__item}>М150</td>
          <td className={styles.table__item}>5 450 <span className={styles.table__item__small}>р/м<sup>3</sup></span></td>
        </tr>
        <tr>
          <td className={styles.table__item}>М100</td>
          <td className={styles.table__item}>5 300 <span className={styles.table__item__small}>р/м<sup>3</sup></span></td>
        </tr>
      </table>
      <table className={styles.table}>
        <tr>
          <th className={styles.table__title}>Раствор</th>
          <th className={styles.table__title}>Цена</th>
        </tr>
        <tr>
          <td className={styles.table__item}>М200</td>
          <td className={styles.table__item}>5 600 <span className={styles.table__item__small}>р/м<sup>3</sup></span></td>
        </tr>
        <tr>
          <td className={styles.table__item}>М150</td>
          <td className={styles.table__item}>5 450 <span className={styles.table__item__small}>р/м<sup>3</sup></span></td>
        </tr>
        <tr>
          <td className={styles.table__item}>М100</td>
          <td className={styles.table__item}>5 300 <span className={styles.table__item__small}>р/м<sup>3</sup></span></td>
        </tr>
      </table>
      <table className={styles.table}>
        <tr>
          <th className={styles.table__title}>Доставка</th>
          <th className={styles.table__title}>Цена</th>
        </tr>
        <tr>
          <td className={styles.table__item}>7 <span className={styles.table__item__small}>м<sup>3</sup></span></td>
          <td className={styles.table__item}>от 4 200 <span className={styles.table__item__small}>р</span></td>
        </tr>
        <tr>
          <td className={styles.table__item}>8 <span className={styles.table__item__small}>м<sup>3</sup></span></td>
          <td className={styles.table__item}>от 4 800 <span className={styles.table__item__small}>р</span></td>
        </tr>
        <tr>
          <td className={styles.table__item}>9 <span className={styles.table__item__small}>м<sup>3</sup></span></td>
          <td className={styles.table__item}>от 5 400 <span className={styles.table__item__small}>р</span></td>
        </tr>
      </table>
      <table className={styles.table}>
        <tr>
          <th className={styles.table__title}>Автобетонасос</th>
          <th className={styles.table__title}>Цена</th>
        </tr>
        <tr>
          <td className={styles.table__item}>22 <span className={styles.table__item__small}>м</span></td>
          <td className={styles.table__item}>24 000 <span className={styles.table__item__small}>р</span></td>
        </tr>
      </table>
    </div>
  );
}

export default Price;
