import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import { PatternFormat } from 'react-number-format';
import styles from './form.module.scss';

function Form() {

  const form = useRef();

  const refCaptcha = useRef();

  const [isOrderComplete, setIsOrderComplete] = useState(false);

  const [isCaptchaError, setIsCaptchaError] = useState(false);

  const [toSend, setToSend] = useState({
    name: '',
    phone: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const token = refCaptcha.current.getValue();

    const params = {
      ...toSend,
      'g-recaptcha-response': token,
    };

    emailjs.send('service_0u6301q', 'template_qbtyv1d', params, 'JNQhqyPZ8PowehLBN', 'g-recaptcha-response')
      .then(() => {
        e.target.reset();
        window.grecaptcha.reset();
        setIsOrderComplete(true);
        setTimeout(() => {
          setIsOrderComplete(false);
        }, 2000);
      }, () => {
        setIsCaptchaError(true);
        setTimeout(() => {
          setIsCaptchaError(false);
        }, 2000);
      });
  };

  const handleChange = (e) => {
    setToSend({...toSend, [e.target.name]: e.target.value });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h2 className={styles.title}>Для получения консультации и расчета стоимости заполните форму</h2>
        {
          isOrderComplete ? (
            <div className={styles.success}>
              <p className={styles.success__text}>Форма успешно отправлена</p>
              <div className={styles.check}></div>
            </div>
          ) : (
            <form ref={form} onSubmit={handleSubmit} className={styles.form} >
              <div className={styles.labels}>
                <label className={styles.label}>
                  <input onChange={handleChange} type="text" name="name" placeholder="Имя" aria-label="Имя" className={styles.input} required></input>
                </label>
                <label className={styles.label}>
                  <PatternFormat format="+7 (###) ### ## ##" allowEmptyFormatting mask="_" onChange={handleChange} id="phone" name="phone" aria-label="Телефон" className={styles.input} required />
                </label>
              </div>
              <div className={styles.captcha}>
                {
                  isCaptchaError && <p className={styles.captcha__error}>Заполните поле &#34;Я не робот&#34;</p>
                }
                <ReCAPTCHA ref={refCaptcha} sitekey="6LeA3qYlAAAAAHrSZ7MifpV4xWtZ8KARskCkxqeE" />
              </div>
              <button className={styles.button} type="submit" aria-label="Отправить">Отправить</button>
            </form>
          )
        }
      </div>

    </div>
  );
}

export default Form;
