import React from 'react';
import nasos from '../../images/nasos.png';
import styles from './nasos.module.scss';

function Nasos() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.description}>
          <h2 className={styles.title}>Аренда автобетононасоса</h2>
          <p className={styles.text}>Аренда автобетононасоса со стрелой 22 м.</p>
          <p className={styles.price}>24 000 р.</p>
        </div>
        <img className={styles.image} src={nasos} width={830} height={360} alt={'Аренда автобетононасоса со стрелой'} />
      </div>
    </div>
  );
}

export default Nasos;
