import React from 'react';
import PropTypes from 'prop-types';
import styles from './tab.module.scss';

function Tab({ tab }) {

  const { name } = tab;

  return (
    <section className={styles.wrapper}>
      {name === 'domodedovo' &&
        <div className={styles.container}>
          <ul className={styles.list}>
            <li className={styles.item}>село Юсупово</li>
            <li className={styles.item}>деревня Сонино</li>
            <li className={styles.item}>микрорайон Барыбино</li>
            <li className={styles.item}>СНТ Матчино</li>
            <li className={styles.item}>деревня Курганье</li>
            <li className={styles.item}>село Ильинское</li>
            <li className={styles.item}>деревня Гальчино</li>
            <li className={styles.item}>деревня Бехтеево</li>
            <li className={styles.item}>деревня Красное</li>
            <li className={styles.item}>село Лобаново</li>
            <li className={styles.item}>село Вельяминово</li>
            <li className={styles.item}>село Михайловское</li>
            <li className={styles.item}>деревня Глотаево</li>
            <li className={styles.item}>деревня Степыгино</li>
            <li className={styles.item}>село Добрыниха</li>
            <li className={styles.item}>село Растуново</li>
            <li className={styles.item}>деревня Одинцово</li>
            <li className={styles.item}>деревня Юсупово</li>
            <li className={styles.item}>посёлок санатория Подмосковье</li>
            <li className={styles.item}>село Красный Путь</li>
            <li className={styles.item}>микрорайон Востряково</li>
            <li className={styles.item}>село Лямцино</li>
            <li className={styles.item}>село Шубино</li>
            <li className={styles.item}>микрорайон Белые Столбы</li>
          </ul>
        </div>}
      {name === 'chehov' &&
        <div className={styles.container}>
          <ul className={styles.list}>
            <li className={styles.item}>деревня Попово</li>
            <li className={styles.item}>село Талалихино</li>
            <li className={styles.item}>посёлок Васькино</li>
            <li className={styles.item}>деревня Крюково</li>
            <li className={styles.item}>деревня Чепелёво</li>
            <li className={styles.item}>деревня Венюково</li>
            <li className={styles.item}>деревня Манушкино</li>
            <li className={styles.item}>посёлок Луч</li>
            <li className={styles.item}>посёлок Любучаны</li>
            <li className={styles.item}>деревня Сандарово</li>
            <li className={styles.item}>деревня Большое Петровское</li>
            <li className={styles.item}>рабочий посёлок Столбовая</li>
            <li className={styles.item}>село Молоди</li>
          </ul>
        </div>}
      {name === 'podolsk' &&
        <div className={styles.container}>
          <ul className={styles.list}>
            <li className={styles.item}>микрорайон Климовск</li>
            <li className={styles.item}>микрорайон Львовский</li>
            <li className={styles.item}>посёлок радиоцентра Романцево</li>
            <li className={styles.item}>посёлок Молодёжный</li>
            <li className={styles.item}>деревня Валищево</li>
            <li className={styles.item}>деревня Бережки</li>
            <li className={styles.item}>село Сынково</li>
          </ul>
        </div>}
      {name === 'stupinskii' &&
        <div className={styles.container}>
          <ul className={styles.list}>
            <li className={styles.item}>село Татариново</li>
            <li className={styles.item}>посёлок Усады</li>
            <li className={styles.item}>рабочий посёлок Михнево</li>
            <li className={styles.item}>село Ивановское</li>
            <li className={styles.item}>село Семёновское</li>
            <li className={styles.item}>деревня Мясное</li>
            <li className={styles.item}>село Липитино</li>
            <li className={styles.item}>село Шугарово</li>
            <li className={styles.item}>деревня Старокурово</li>
            <li className={styles.item}>деревня Ольховка</li>
            <li className={styles.item}>село Константиновское</li>
          </ul>
        </div>}
    </section>
  );
}

Tab.propTypes = {
  tab: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
};

export default Tab;
