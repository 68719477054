import React, { useState } from 'react';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import Tab from '../tab/tab';
import { tabs } from '../../const';
import styles from './tabs.module.scss';

function Tabs() {
  const isDesktop = useMediaQuery({
    query: '(min-width: 769px)',
  });
  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });


  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = (tab) => {
    setActiveTab(tab);
  };

  const handleItemClick = (tab) => {
    setActiveTab(tab);
    setIsOpen(false);
  };

  return (
    <section className={styles.wrapper}>
      <h2 className={styles.title}>Пункты доставки бетона</h2>
      <div className={styles.container}>
        {isDesktop &&
        <div className={styles.tabs}>
          {
            tabs.map((tab) => (
              <button
                className={cn(styles.button, activeTab === tab && styles.button_active)}
                key={tab.id}
                type="button"
                onClick={() => {
                  handleButtonClick(tab);
                }}
                aria-label={tab.tabName}
              >
                <span className={cn(`${tab.name}`, styles.icon)}></span>
                {tab.tabName}
              </button>
            ))
          }
        </div>}
        {isTabletOrMobile &&
        <div className={styles.tabs}>
          <button
            type='button'
            onClick={() => setIsOpen(!isOpen)}
            className={cn(styles.button, styles.button_active, isOpen && styles.button_open)}
            aria-label="Открыть варианты"
          >
            {activeTab.tabName}
          </button>
          {isOpen &&
          <ul className={styles.list}>
            {
              tabs.map((tab) => (
                <li className={styles.item} key={tab.id}>
                  <button
                    className={styles.button_list}
                    key={tab.id}
                    type="button"
                    onClick={() => {
                      handleItemClick(tab);
                    }}
                    aria-label={tab.tabName}
                  >
                    <span className={cn(`${tab.name}`, styles.icon)}></span>
                    {tab.tabName}
                  </button>
                </li>
              ))
            }
          </ul>}
        </div>}
        <div className={styles.services}>
          <Tab tab={activeTab}/>
        </div>
      </div>
    </section>
  );
}

export default Tabs;
