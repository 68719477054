import React from 'react';
import zavod from '../../images/zavod-zarya.jpeg';
import styles from './about.module.scss';

function About() {
  return (
    <div className={styles.wrapper}>
      <img className={styles.image} src={zavod} width={1041} height={720} alt={'Бетонный завод Заря'} />
      <div>
        <h2 className={styles.title}>Бетонный завод &#171;Заря Бетон&#187;</h2>
        <p className={styles.text}>Мы гарантируем качество нашего бетона, обеспечивая оптимальную плотность и прочность материала. Все марки производятся на собственном заводе под строгим контролем.</p>
        <p className={styles.text}>Лаборатория на территории завода позволяет соблюдать все этапы производства. Каждая марка бетона соответствуют ГОСТу.</p>
        <p className={styles.text}>Мы предоставляем полный комплект документов на отгрузку. Это обеспечивает прозрачность нашей работы и уверенность в качестве продукции.</p>
        <p className={styles.text}>Заказать бетон и его доставку можно в любое время, включая выходные и праздничные дни. Благодаря нашей гибкости мы можем удовлетворить потребности клиентов в самых разных условиях.</p>
      </div>
    </div>
  );
}

export default About;
