import React from 'react';
import Header from '../header/header';
import MainTop from '../main-top/main-top';
import Price from '../price/price';
import Form from '../form/form';
import About from '../about/about';
import Nasos from '../nasos/nasos';
import Tabs from '../tabs/tabs';
import YandexMap from '../yandex-map/yandex-map';
import Footer from '../footer/footer';
import styles from './main-page.module.scss';

function MainPage() {
  return (
    <div className={styles.wrapper}>
      <Header />
      <main className={styles.main}>
        <MainTop />
        <Price />
        <Form />
        <About />
        <Nasos />
        <Tabs />
        <YandexMap />
      </main>
      <Footer />
    </div>
  );
}

export default MainPage;
