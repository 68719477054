import React from 'react';
import cn from 'classnames';
import styles from './header.module.scss';

function Header() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <p className={cn(styles.text, styles.text__time)}>Круглосуточно, без выходных</p>
        <a href='https://yandex.ru/maps/org/su_19/184586261142/?ll=37.759870%2C55.255354&z=15' target='_blanck' className={cn(styles.text, styles.text__address)}>Московская область,<br /> г.о. Домодедово, д. Шишкино</a>
        <a href='mailto:zarya-beton@mail.ru' target='_blanck' className={cn(styles.text, styles.text__mail)}>zarya-beton@mail.ru</a>
        <div className={styles.links}>
          <a href='tel:+74959283131' target='_blanck' className={cn(styles.link, styles.link__phone)}>+7 (495) 928-31-31</a>
          <a href='tel:+79199679613' target='_blanck' className={cn(styles.link, styles.link__phone)}>+7 (919) 967-96-13</a>
          <a href='https://wa.me/+79199679613' target='_blanck' className={cn(styles.link, styles.link__whatsapp)}>WhatsApp</a>
        </div>
      </div>
    </div>
  );
}

export default Header;
