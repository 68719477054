import React from 'react';
import styles from './yandex-map.module.scss';

function YandexMap() {

  return (
    <div className={styles.wrapper} dangerouslySetInnerHTML={{
      __html: '<iframe src=\'https://yandex.ru/map-widget/v1/?z=15&ol=biz&oid=184586261142\' width=\'100%\' height=\'600\' frameborder = \'0\' ></iframe> ',
    }}
    >
    </div>
  );
}

export default YandexMap;
