import React from 'react';
import cn from 'classnames';
import styles from './main-top.module.scss';

function MainTop() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.description}>
          <p className={styles.name}>Бетонный завод &#171;Заря Бетон&#187;</p>
          <h1 className={styles.title}>Бетон с доставкой от производителя по Москве и Московской области</h1>
        </div>
        <div>
          <ul className={styles.list}>
            <li className={cn(styles.item, styles.item__zavod)}>Свой бетонный завод</li>
            <li className={cn(styles.item, styles.item__time)}>Среднее время доставки - 1 час</li>
            <li className={cn(styles.item, styles.item__gost)}>Весь бетон по ГОСТу</li>
            <li className={cn(styles.item, styles.item__car)}>Свой парк АБС</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default MainTop;
