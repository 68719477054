import React from 'react';
import styles from './footer.module.scss';

function Footer() {
  return (
    <div className={styles.wrapper}>
      {/* <div className={styles.container}>
        <h2>Доставка </h2>
      </div> */}
      <p className={styles.text}>Бетонный завод &#171;Заря Бетон&#187; - Copyright © 2024</p>
    </div>
  );
}

export default Footer;
